<template>
  <div>
    <div v-if="isThereData">
      <b-pagination size="sm" align="fill"
        v-model="currentPage"
        :total-rows="totalRows"
        :per-page="perPage"
        :disabled="!getAvailableList"
        aria-controls="inversionistas-table"
      ></b-pagination>
    </div>
    <!-- Table -->
    <b-table
      id="inversionistasTable"
      v-if="getAvailableList"
      :fields="fields"
      :items="getList"
      striped
      bordered
      responsive
      hover
      stacked="lg"
      show-empty
      small
      :per-page="perPage"
      :current-page="currentPage"
      :filter="filter"
      :filter-included-fields="filterOn"
      @filtered="onFiltered"
      empty-filtered-text="No se encontraron inversionistas con criterio de búsqueda escrito"
    >
      <!-- Eliminado -->
      <template #cell(is_deleted)="row">
        <b-badge v-if="row.item.is_deleted" variant="danger">No</b-badge>
        <b-badge v-else variant="light">Sí</b-badge>
      </template>

      <!-- Identificación -->
      <template #cell(identificacion)="row">
        <filtered-row :value="row.item.identificacion" :filter="filter"/>
        <b-badge :variant="row.item.verificado?'success':'danger'" v-b-tooltip.hover
                 :title="!row.item.verificado?'La información de este inversionista no se ha verificado, solicite que adjunte su constancia de situación fiscal':'La información de este inversionista se ha verificado con la constancia de situación fiscal'">
          {{ row.item.verificado ? 'Información verificada' : 'Información no verificada' }}
        </b-badge>
      </template>

      <!-- Email -->
      <template #cell(email)="row">
        <filtered-row :value="row.item.email" :filter="filter"/>
      </template>

      <!-- RFC -->
      <template #cell(rfc)="row">
        <filtered-row :value="row.item.rfc" :filter="filter"/>
      </template>

      <!-- Reside en Mérida -->
      <template #cell(reside_merida)="row">
        <span class="text-sm text-center w-100" :class="{ 'text-muted': row.item.is_deleted }">
          {{ row.item.reside_merida == 'S' ? 'Sí':'No' }}
        </span>
      </template>

      <!-- Año Ingreso -->
      <template #cell(anio_ingreso)="row">
        <span class="text-sm text-center w-100" :class="{ 'text-muted': row.item.is_deleted }">
          {{ row.item.anio_ingreso }}
        </span>
      </template>

      <!-- Tipo Contribuyente -->
      <template #cell(tipo_persona)="row">
        <span class="text-sm text-center w-100" :class="{ 'text-muted': row.item.is_deleted }">
          {{ row.item.tipo_persona }}
        </span>
      </template>

      <!-- Régimen Fiscal -->
      <template #cell(regimen_fiscal.descripcion)="row">
        <span class="text-sm text-center w-100" :class="{ 'text-muted': row.item.is_deleted }">
          {{ row.item.regimen_fiscal ? row.item.regimen_fiscal.descripcion : '' }}
        </span>
      </template>

      <!-- Detalle -->
      <template #cell(show_details)="row">
        <b-button
          @click="row.toggleDetails"
          variant="light"
          class="btn-sm mb-2 btn-block btn-outline-black"
        >
          Detalles
        </b-button>

        <check-authorization :requiresRoles="['admin']" class="w-100" v-if="!row.item.is_deleted && row.item.user_id">
          <b-button
            class="btn-sm mb-2 btn-block btn-outline-indigo"
            variant="light"
            @click="viewAsUser(row.item.user_id)"
            v-if="!isAuthUserOrCloneUser(row.item.id)"
          >
            Ver como
          </b-button>
        </check-authorization>
      </template>

      <!-- Acciones -->
      <template #cell(acciones)="row">
        <div class="d-flex justify-content-center" v-if="!row.item.is_deleted">
          <x-password-reset-button
            v-if="row.item.email"
            :user-id="row.item.id_usuario"
            :email="row.item.email"
          >
          </x-password-reset-button>
          <u-d-controls
            :id="row.item.id"
            editPath="inversionistaModule/getResourceToEdit"
            deletePath="inversionistaModule/deleteResource"
            :editPermissions="['actualizar inversionistas']"
            :deletePermissions="['eliminar inversionistas']"
            @on-got-item="emitOnGotItem"
          ></u-d-controls>
        </div>
      </template>

      <!-- Acciones -->
      <template #row-details="row">
        <inversionista-list-detail :row="row"></inversionista-list-detail>
      </template>
    </b-table>
    <loading v-else></loading>
  </div>
</template>

<script>
import { mapGetters } from 'vuex'
import InversionistaListDetail from './InversionistaListDetail'
import XPasswordResetButton from '@/components/Shared/XPasswordResetButton.vue'
import FilteredRow from '@/components/Common/Table/FilteredRow.vue'

const API_MODULE = 'inversionistaModule'

export default {
  name: 'InversionistaList',

  components: {
    InversionistaListDetail,
    XPasswordResetButton,
    FilteredRow
  },

  created () {
    this.submit()
  },

  props: {
    search: {
      required: true
    },
    filter: {
      required: true
    }
  },

  data () {
    return {
      fields: [
        { key: 'identificacion', label: 'Inversionista', sortable: true },
        { key: 'email', label: 'Correo' },
        { key: 'rfc', label: 'RFC' },
        { key: 'reside_merida', label: 'Reside en Mérida' },
        { key: 'anio_ingreso', label: 'Inversionista desde' },
        { key: 'tipo_persona', label: 'Tipo de persona' },
        { key: 'regimen_fiscal.descripcion', label: 'Régimen Fiscal' },
        { key: 'show_details', label: 'Detalle' },
        { key: 'acciones', label: 'Acciones' }
      ],

      perPage: 10,

      currentPage: 1,

      totalRows: 0,

      filterOn: ['identificacion', 'email', 'rfc']
    }
  },

  computed: {
    ...mapGetters(API_MODULE, [
      'getList',
      'getAvailableList',
      'isThereData',
      'getResource',
      'getTotalItems'
    ]),
    ...mapGetters('userModule', [
      'isAuthUserOrCloneUser'
    ])
  },

  watch: {
    search: {
      immediate: true,
      handler (value) {
        this.getlist(value)
      }
    }
  },

  methods: {
    async submit () {
      const { error, message } = await this.$store.dispatch(`${API_MODULE}/getResource`)
      this.totalRows = this.getTotalItems

      if (error) this.$notify({ error, message }, 'Inversionistas')
    },

    async getlist (value) {
      const { error, message } = await this.$store.dispatch(`${API_MODULE}/getResource`, value)
      this.totalRows = this.getTotalItems

      if (error) this.$notify({ error, message }, 'Inversionistas')
    },

    onFiltered (filteredItems) {
      // Trigger pagination to update the number of buttons/pages due to filtering
      this.totalRows = filteredItems.length
      this.currentPage = 1
    },

    async viewAsUser (id) {
      await this.$store.dispatch('userModule/getViewAsUser', id)
      this.$router.push({ name: 'Dashboard' })
      window.scrollTo(0, 0)
    },

    emitOnGotItem () {
      this.$emit('on-got-item')
    }
  }
}
</script>
