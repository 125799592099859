<script>
import { mapGetters } from 'vuex'

const STORE_MODULE = 'inversionistaModule'
export default {
  name: 'InversionistaListDetail',

  props: {
    row: {
      required: true
    }
  },

  data () {
    return {
      historialPagosFields: [
        { key: 'fecha_pago', label: 'Fecha de pago' },
        { key: 'importe_pago', label: 'Importe' },
        { key: 'archivos', label: 'Archivos' }
      ]
    }
  },

  computed: {
    ...mapGetters(STORE_MODULE, [
      'getResourceList',
      'getAvailableList',
      'getTotalRows',
      'isThereData',
      'getResource'
    ])
  },

  methods: {
    async onDownloadFile (archivo) {
      const result = await this.$store.dispatch('archivoModule/download', archivo.id)

      await this.$onDownload(result, archivo.original_name)

      this.$notify({ message: `Descarga de archivo ${archivo.original_name}.` })
    }
  }
}
</script>

<template>
  <b-card
    class="mb-1"
    border-variant="primary"
    header-bg-variant="primary"
    header-text-variant="white"
    header="Detalle"
    no-body
  >
    <b-card-body>
      <b-row class="mb-1">
        <b-col cols="12" md="6">
          <b-card
            header="Información fiscal del inversionista"
            no-body
            border-variant="primary"
            class="mb-4"
            style="overflow: auto;"
          >
            <b-list-group flush>
              <b-list-group-item v-if="row.item.tipo_persona" class="d-flex justify-content-between align-items-center">
                <span><b>Tipo de persona:</b> {{ row.item.tipo_persona }}</span>
                <b-badge :variant="row.item.verificado?'success':'danger'" pill>
                  <i :data-feather="row.item.verificado?'check-circle':'alert-circle'"></i>
                </b-badge>
              </b-list-group-item>

              <b-list-group-item v-if="row.item.rfc" class="d-flex justify-content-between align-items-center">
                <span><b>RFC:</b> {{ row.item.rfc }}</span>
                <b-badge :variant="row.item.verificado?'success':'danger'" pill>
                  <i :data-feather="row.item.verificado?'check-circle':'alert-circle'"></i>
                </b-badge>
              </b-list-group-item>

              <b-list-group-item v-if="row.item.tipo_persona == 'FISICA'" class="d-flex justify-content-between align-items-center">
                <span><b>CURP:</b> {{ row.item.persona_fisica.curp }}</span>
                <b-badge :variant="row.item.persona_fisica.verificado?'success':'danger'" pill>
                  <i :data-feather="row.item.persona_fisica.verificado?'check-circle':'alert-circle'"></i>
                </b-badge>
              </b-list-group-item>

              <b-list-group-item v-if="row.item.regimen_fiscal && !row.item.verificado" class="d-flex justify-content-between align-items-center">
                <span><b>Régimen fiscal:</b> {{ row.item.regimen_fiscal.descripcion }}</span>
                <b-badge :variant="row.item.verificado?'success':'danger'" pill>
                  <i :data-feather="row.item.verificado?'check-circle':'alert-circle'"></i>
                </b-badge>
              </b-list-group-item>

              <b-list-group-item v-if="row.item.regimenes_fiscales && row.item.verificado" class="d-flex justify-content-between align-items-baseline">
                <span><b>Régimen(es) fiscal(es):</b>
                  <ul>
                    <li v-for="regimen_fiscal in row.item.regimenes_fiscales" v-bind:key="regimen_fiscal.id">
                      {{ regimen_fiscal.descripcion }}
                    </li>
                  </ul>
                </span>
                <b-badge :variant="row.item.verificado?'success':'danger'" pill>
                  <i :data-feather="row.item.verificado?'check-circle':'alert-circle'"></i>
                </b-badge>
              </b-list-group-item>

              <b-list-group-item v-if="row.item.tipo_persona == 'MORAL'" class="d-flex justify-content-between align-items-center">
                <span><b>Razón social:</b> {{ row.item.persona_moral.razon_social }}</span>
                <b-badge :variant="row.item.persona_moral.verificado?'success':'danger'" pill>
                  <i :data-feather="row.item.persona_moral.verificado?'check-circle':'alert-circle'"></i>
                </b-badge>
              </b-list-group-item>

              <b-list-group-item v-if="row.item.tipo_persona == 'FISICA'" class="d-flex justify-content-between align-items-center">
                <span><b>Nombres:</b> {{ row.item.persona_fisica.nombres }}</span>
                <b-badge :variant="row.item.persona_fisica.verificado?'success':'danger'" pill>
                  <i :data-feather="row.item.persona_fisica.verificado?'check-circle':'alert-circle'"></i>
                </b-badge>
              </b-list-group-item>

              <b-list-group-item v-if="row.item.tipo_persona == 'FISICA' && !row.item.persona_fisica.verificado" class="d-flex justify-content-between align-items-center">
                <span><b>Apellidos:</b> {{ row.item.persona_fisica.apellidos }}</span>
                <b-badge :variant="row.item.persona_fisica.verificado?'success':'danger'" pill>
                  <i :data-feather="row.item.persona_fisica.verificado?'check-circle':'alert-circle'"></i>
                </b-badge>
              </b-list-group-item>

              <b-list-group-item v-if="row.item.tipo_persona == 'FISICA' && row.item.persona_fisica.verificado" class="d-flex justify-content-between align-items-center">
                <span><b>Primer apellido:</b> {{ row.item.persona_fisica.primer_apellido }}</span>
                <b-badge :variant="row.item.persona_fisica.verificado?'success':'danger'" pill>
                  <i :data-feather="row.item.persona_fisica.verificado?'check-circle':'alert-circle'"></i>
                </b-badge>
              </b-list-group-item>

              <b-list-group-item v-if="row.item.tipo_persona == 'FISICA' && row.item.persona_fisica.verificado"  class="d-flex justify-content-between align-items-center">
                <span><b>Segundo apellido:</b> {{ row.item.persona_fisica.segundo_apellido }}</span>
                <b-badge :variant="row.item.persona_fisica.verificado?'success':'danger'" pill>
                  <i :data-feather="row.item.persona_fisica.verificado?'check-circle':'alert-circle'"></i>
                </b-badge>
              </b-list-group-item>
            </b-list-group>
          </b-card>
          <b-card
            header="Datos del inversionista"
            no-body
            border-variant="primary"
            class="mb-4"
            style="overflow: auto;"
          >
            <b-list-group flush>
              <!-- Correo electronico -->
              <b-list-group-item v-if="row.item.email">
                <b>Correo electrónico:</b>
                <span v-if="$isEmailValid(row.item.email)">
                        {{ row.item.email }}
                      </span>
                <span v-else class="text-danger font-weight-bold">
                        {{ row.item.email }}
                      </span>
              </b-list-group-item>

              <!-- Año de ingreso -->
              <b-list-group-item v-if="row.item.anio_ingreso">
                <b>Año de ingreso:</b>
                {{ row.item.anio_ingreso }}
              </b-list-group-item>

              <!-- Notas -->
              <b-list-group-item v-if="row.item.notas">
                <b>Notas:</b>
                {{ row.item.notas }}
              </b-list-group-item>

              <div v-if="row.item.tipo_persona == 'FISICA'">
                <!-- Fecha de nacimiento -->
                <b-list-group-item v-if="row.item.persona_fisica.fecha_nacimiento">
                  <b>Fecha de nacimiento:</b>
                  {{ row.item.persona_fisica.fecha_nacimiento }}
                </b-list-group-item>

                <!-- Lugar de nacimiento -->
                <b-list-group-item v-if="row.item.persona_fisica.lugar_nacimiento">
                  <b>Lugar de nacimiento:</b>
                  {{ row.item.persona_fisica.lugar_nacimiento }}
                </b-list-group-item>

                <!-- Estado civil -->
                <b-list-group-item v-if="row.item.persona_fisica.estado_civil">
                  <b>Estado civil:</b>
                  {{ row.item.persona_fisica.estado_civil }}
                </b-list-group-item>

                <!-- Regimen conyugal -->
                <b-list-group-item v-if="row.item.persona_fisica.regimen_conyugal">
                  <b>Régimen conyugal:</b>
                  {{ row.item.persona_fisica.regimen_conyugal }}
                </b-list-group-item>

                <!-- Tipo de identificacion -->
                <b-list-group-item v-if="row.item.persona_fisica.tipo_identificacion">
                  <b>Tipo de identificación:</b>
                  {{ row.item.persona_fisica.tipo_identificacion }}
                </b-list-group-item>

                <!-- Folio de identificacion -->
                <b-list-group-item v-if="row.item.persona_fisica.folio_identificacion">
                  <b>Folio de identificación:</b>
                  {{ row.item.persona_fisica.folio_identificacion }}
                </b-list-group-item>
              </div>
              <div v-else>
                <!-- Fecha de constitucion -->
                <b-list-group-item v-if="row.item.persona_moral.fecha_constitucion">
                  <b>Fecha de constitución:</b>
                  {{ row.item.persona_moral.fecha_constitucion }}
                </b-list-group-item>

                <!-- Lugar de constitucion -->
                <b-list-group-item v-if="row.item.persona_moral.lugar_constitucion">
                  <b>Lugar de constitución:</b>
                  {{ row.item.persona_moral.lugar_constitucion }}
                </b-list-group-item>
              </div>
            </b-list-group>
          </b-card>
          <b-card
            header="Medio de pago del inversionista"
            no-body
            border-variant="primary"
            class="mb-4"
            style="overflow: auto;"
          >
            <b-list-group flush>
              <!-- Institucion bancaria -->
              <b-list-group-item v-if="row.item.institucion_bancaria">
                <b>Institución bancaria:</b>
                {{ row.item.institucion_bancaria.nombre }}
              </b-list-group-item>

              <!-- Beneficiario -->
              <b-list-group-item v-if="row.item.beneficiario">
                <b>Beneficiario:</b>
                {{ row.item.beneficiario }}
              </b-list-group-item>

              <!-- CLABE -->
              <b-list-group-item v-if="row.item.clabe">
                <b>CLABE:</b>
                {{ row.item.clabe }}
              </b-list-group-item>

              <!-- Forma de pago -->
              <b-list-group-item v-if="row.item.forma_pago">
                <b>Forma de pago:</b>
                {{ row.item.forma_pago }}
              </b-list-group-item>

              <!-- No de cuenta -->
              <b-list-group-item v-if="row.item.cuenta">
                <b>No. de cuenta:</b>
                {{ row.item.cuenta }}
              </b-list-group-item>

              <!-- No de tarjeta -->
              <b-list-group-item v-if="row.item.num_tarjeta">
                <b>No. de tarjeta:</b>
                {{ row.item.num_tarjeta }}
              </b-list-group-item>
            </b-list-group>
          </b-card>
          <b-card
            :header="`Dirección del inversionista  ${row.item.domicilio ? '' : '(no registrado)'}`"
            no-body
            border-variant="primary"
            class="mb-4"
            style="overflow: auto;"
          >
            <b-list-group v-if="row.item.domicilio" flush>
              <!-- Calle -->
              <b-list-group-item v-if="row.item.domicilio.calle"  class="d-flex justify-content-between align-items-center">
                <span><b>Calle:</b> {{ row.item.domicilio.calle }}</span>
                <b-badge :variant="row.item.domicilio.verificado?'success':'danger'" pill>
                  <i :data-feather="row.item.domicilio.verificado?'check-circle':'alert-circle'"></i>
                </b-badge>
              </b-list-group-item>

              <!-- No. exterior -->
              <b-list-group-item v-if="row.item.domicilio.no_exterior"  class="d-flex justify-content-between align-items-center">
                <span><b>No. exterior:</b> {{ row.item.domicilio.no_exterior }}</span>
                <b-badge :variant="row.item.domicilio.verificado?'success':'danger'" pill>
                  <i :data-feather="row.item.domicilio.verificado?'check-circle':'alert-circle'"></i>
                </b-badge>
              </b-list-group-item>

              <!-- No. interior -->
              <b-list-group-item v-if="row.item.domicilio.no_interior"  class="d-flex justify-content-between align-items-center">
                <span><b>No. interior:</b> {{ row.item.domicilio.no_interior }}</span>
                <b-badge :variant="row.item.domicilio.verificado?'success':'danger'" pill>
                  <i :data-feather="row.item.domicilio.verificado?'check-circle':'alert-circle'"></i>
                </b-badge>
              </b-list-group-item>

              <!-- Referencia -->
              <b-list-group-item v-if="row.item.domicilio.referencia"  class="d-flex justify-content-between align-items-center">
                <span><b>Referencia:</b> {{ row.item.domicilio.referencia }}</span>
                <b-badge :variant="row.item.domicilio.verificado?'success':'danger'" pill>
                  <i :data-feather="row.item.domicilio.verificado?'check-circle':'alert-circle'"></i>
                </b-badge>
              </b-list-group-item>

              <!-- Colonia -->
              <b-list-group-item v-if="row.item.domicilio.colonia"  class="d-flex justify-content-between align-items-center">
                <span><b>Colonia:</b> {{ row.item.domicilio.colonia }}</span>
                <b-badge :variant="row.item.domicilio.verificado?'success':'danger'" pill>
                  <i :data-feather="row.item.domicilio.verificado?'check-circle':'alert-circle'"></i>
                </b-badge>
              </b-list-group-item>

              <!-- Código postal -->
              <b-list-group-item v-if="row.item.domicilio.codigo_postal"  class="d-flex justify-content-between align-items-center">
                <span><b>Código postal:</b> {{ row.item.domicilio.codigo_postal }}</span>
                <b-badge :variant="row.item.domicilio.verificado?'success':'danger'" pill>
                  <i :data-feather="row.item.domicilio.verificado?'check-circle':'alert-circle'"></i>
                </b-badge>
              </b-list-group-item>

              <!-- Localidad -->
              <b-list-group-item v-if="row.item.domicilio.localidad"  class="d-flex justify-content-between align-items-center">
                <span><b>Localidad:</b> {{ row.item.domicilio.localidad }}</span>
                <b-badge :variant="row.item.domicilio.verificado?'success':'danger'" pill>
                  <i :data-feather="row.item.domicilio.verificado?'check-circle':'alert-circle'"></i>
                </b-badge>
              </b-list-group-item>

              <!-- Municipio -->
              <b-list-group-item v-if="row.item.domicilio.municipio"  class="d-flex justify-content-between align-items-center">
                <span><b>Municipio:</b> {{ row.item.domicilio.municipio }}</span>
                <b-badge :variant="row.item.domicilio.verificado?'success':'danger'" pill>
                  <i :data-feather="row.item.domicilio.verificado?'check-circle':'alert-circle'"></i>
                </b-badge>
              </b-list-group-item>

              <!-- Estado -->
              <b-list-group-item v-if="row.item.domicilio.estado"  class="d-flex justify-content-between align-items-center">
                <span><b>Estado:</b> {{ row.item.domicilio.estado }}</span>
                <b-badge :variant="row.item.domicilio.verificado?'success':'danger'" pill>
                  <i :data-feather="row.item.domicilio.verificado?'check-circle':'alert-circle'"></i>
                </b-badge>
              </b-list-group-item>

              <!-- País -->
              <b-list-group-item v-if="row.item.domicilio.pais"  class="d-flex justify-content-between align-items-center">
                <span><b>País:</b> {{ row.item.domicilio.pais }}</span>
                <b-badge :variant="row.item.domicilio.verificado?'success':'danger'" pill>
                  <i :data-feather="row.item.domicilio.verificado?'check-circle':'alert-circle'"></i>
                </b-badge>
              </b-list-group-item>
            </b-list-group>
          </b-card>
          <b-card
            header="Medios de contacto del inversionista"
            no-body
            border-variant="primary"
            class="mb-4"
            style="overflow: auto;"
          >
            <b-list-group flush>
              <!-- Contacto1 -->
              <b-list-group-item v-if="row.item.contacto1">
                <b>Contacto 1:</b>
                {{ row.item.contacto1 }}
              </b-list-group-item>

              <!-- Correo1 -->
              <b-list-group-item v-if="row.item.correo1">
                <b>Correo electrónico principal (Contacto 1):</b>
                {{ row.item.correo1 }}
              </b-list-group-item>

              <!-- Correo1a -->
              <b-list-group-item v-if="row.item.correo1a">
                <b>Correo electrónico secundario (Contacto 1):</b>
                {{ row.item.correo1a }}
              </b-list-group-item>

              <!-- Telefono1 -->
              <b-list-group-item v-if="row.item.telefono1">
                <b>Teléfono (Contacto 1):</b>
                {{ row.item.telefono1 }}
              </b-list-group-item>

              <b-list-group-item v-if="row.item.contacto2"></b-list-group-item>

              <!-- Contacto2 -->
              <b-list-group-item v-if="row.item.contacto2">
                <b>Contacto 2:</b>
                {{ row.item.contacto2 }}
              </b-list-group-item>

              <!-- Correo2 -->
              <b-list-group-item v-if="row.item.correo2">
                <b>Correo electrónico principal (Contacto 2):</b>
                {{ row.item.correo2 }}
              </b-list-group-item>

              <!-- Telefono2 -->
              <b-list-group-item v-if="row.item.telefono2">
                <b>Teléfono (Contacto 2):</b>
                {{ row.item.telefono2 }}
              </b-list-group-item>
            </b-list-group>
          </b-card>
          <b-col cols="12" md="6" v-if="row.item.contador">
            <b-card
              header="Datos del contador"
              no-body
              border-variant="primary"
              class="mb-4"
              style="overflow: auto;"
            >
              <b-list-group flush>
                <!-- Contador : razon social -->
                <b-list-group-item v-if="row.item.contador.razon_social">
                  <b>Razón social:</b>
                  {{ row.item.contador.razon_social }}
                </b-list-group-item>

                <!-- Contador : RFC -->
                <b-list-group-item v-if="row.item.contador.rfc">
                  <b>RFC:</b>
                  {{ row.item.contador.rfc }}
                </b-list-group-item>

                <!-- Contador : email -->
                <b-list-group-item v-if="row.item.contador.email">
                  <b>Correo electrónico:</b>
                  {{ row.item.contador.email }}
                </b-list-group-item>

                <!-- Contador : telefonos -->
                <b-list-group-item v-if="row.item.contador.telefonos">
                  <b>Teléfonos:</b>
                  <div class="badge badge-info ml-1" v-for="telefono in row.item.contador.telefonos" :key="telefono">
                    {{ telefono }}
                  </div>
                </b-list-group-item>
              </b-list-group>
            </b-card>
          </b-col>
        </b-col>
        <b-col cols="12" md="6">
          <b-card
            header="Historial de pagos"
            no-body
            border-variant="success"
            header-text-variant="success"
            class="mb-4"
            style="overflow: auto;"
          >
            <b-table
              id="historialActualizacionesImporteTable"
              v-if="getAvailableList"
              :fields="historialPagosFields"
              :items="row.item.historial_pagos"
              striped
              bordered
              responsive
              hover
              stacked="lg"
              show-empty
              small
              empty-text="No existe información de pagos registrados para este departamento"
            >
              <template #cell(fecha_pago)="row">
                <span class="text-sm text-center w-100">
                  {{ row.item.fecha_pago }}
                </span>
              </template>
              <template #cell(importe_pago)="row">
                <span class="text-sm text-center w-100">
                  {{ $formatNumber(row.item.importe_pago) }}
                </span>
              </template>
              <template #cell(archivos)="row">
                <span class="text-sm text-left w-100">
                  <ul>
                    <li v-for="archivo in row.item.archivos" v-bind:key="archivo.id">
                      <b-link @click="onDownloadFile(archivo)">
                        {{ archivo.original_name }}
                      </b-link>
                    </li>
                  </ul>
                </span>
              </template>
            </b-table>
          </b-card>
        </b-col>
      </b-row>
      <b-card no-body>
        <b-button
          variant="white"
          size="sm"
          class="btn-outline-black"
          @click="row.toggleDetails"
        >
          Cerrar detalle
        </b-button>
      </b-card>
    </b-card-body>
  </b-card>
</template>

<style scoped>

</style>
