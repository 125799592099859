<template>
  <b-input-group size="sm" class="w-100 mx-2" style="max-width: 400px">
    <b-input-group-prepend>
      <b-input-group-text class="py-0">
        <b-icon-search></b-icon-search>
      </b-input-group-text>
    </b-input-group-prepend>

    <input
      v-model="filter"
      :placeholder="this.placeholder ? this.placeholder : 'Buscar'"
      type="search"
      class="form-control"
      :disabled="this.disabled"
    />
  </b-input-group>
</template>

<script>
import { mapGetters } from 'vuex'

export default {
  name: 'InversionistaSearch',

  data () {
    return {
      filter: ''
    }
  },

  props: {
    placeholder: {
      type: String,
      required: false
    },

    disabled: {
      type: Boolean,
      required: false
    }
  },

  computed: {
    ...mapGetters('inversionistaModule', ['isSearchingMode'])
  },

  watch: {
    filter: {
      immediate: true,
      handler (val) {
        this.$emit('filter', this.filter)
      }
    }
  }
}
</script>
