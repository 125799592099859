<template>
  <div>
    <b-button v-b-modal="'modalInversionistaImportForm'" variant="warning" size="sm" class="mr-2">Importar</b-button>
    <b-modal
      id="modalInversionistaImportForm"
      v-model="modalInversionistaImportForm"
      centered
      scrollable
      no-close-on-backdrop
      no-close-on-esc
      hide-header-close
      dialog-class="x-modal-md"
    >
      <template #modal-title>
        <span class="text-danger">Importar formato de inversionistas</span>
      </template>

      <x-alert-with-errors
        :error="response.error"
        :title="response.message"
        :errors="response.errors"
      />

      <b-overlay :show="isLoading">
        <template #overlay>
          <div class="text-center">
            <loading message="Procesando información, por favor espere..." />
          </div>
        </template>

        <b-card :class="{ isLoading }">
          <b-form v-if="!(response.data)" @submit.prevent="submit">
            <!-- Archivo layout -->
            <b-form-group>
              <template #label>
                <label class="small font-weight-bolder">
                  Archivo layout
                  <span class="text-danger">*</span>
                </label>
              </template>

              <b-form-file
                size="sm"
                v-model="payload.file"
                :state="Boolean(payload.file)"
                placeholder="Selecciona el archivo layout excel...">
                <template slot="file-name" slot-scope="{ names }">
                  <b-badge variant="dark">{{ names[0] }}</b-badge>
                </template>
              </b-form-file>
            </b-form-group>
          </b-form>
          <div v-else>
            <b-table
              id="responseTable"
              :fields="fields"
              :items="response.data"
              striped
              bordered
              responsive
              hover
              stacked="lg"
              show-empty
              small
            >
              <template #cell(persona_rfc)="row">
                <div class="text-danger" v-b-tooltip.hover :title="row.value.error" v-if="row.value.error!==''">{{ row.value.data }}</div>
                <div class="text-primary" v-else>{{ row.value.data }}</div>
              </template>
              <template #cell(persona_curp)="row">
                <div class="text-danger" v-b-tooltip.hover :title="row.value.error" v-if="row.value.error!==''">{{ row.value.data }}</div>
                <div class="text-primary" v-else>{{ row.value.data }}</div>
              </template>
              <template #cell(persona_regimen_fiscal)="row">
                <div class="text-danger" v-b-tooltip.hover :title="row.value.error" v-if="row.value.error!==''">{{ row.value.data }}</div>
                <div class="text-primary" v-else>{{ row.value.data }}</div>
              </template>
              <template #cell(persona_nombres_razon_social)="row">
                <div class="text-danger" v-b-tooltip.hover :title="row.value.error" v-if="row.value.error!==''">{{ row.value.data }}</div>
                <div class="text-primary" v-else>{{ row.value.data }}</div>
              </template>
              <template #cell(persona_primer_apellido)="row">
                <div class="text-danger" v-b-tooltip.hover :title="row.value.error" v-if="row.value.error!==''">{{ row.value.data }}</div>
                <div class="text-primary" v-else>{{ row.value.data }}</div>
              </template>
              <template #cell(persona_segundo_apellido)="row">
                <div class="text-danger" v-b-tooltip.hover :title="row.value.error" v-if="row.value.error!==''">{{ row.value.data }}</div>
                <div class="text-primary" v-else>{{ row.value.data }}</div>
              </template>
              <template #cell(persona_fecha_nacimiento_constitucion)="row">
                <div class="text-danger" v-b-tooltip.hover :title="row.value.error" v-if="row.value.error!==''">{{ row.value.data }}</div>
                <div class="text-primary" v-else>{{ row.value.data }}</div>
              </template>
              <template #cell(persona_lugar_nacimiento_constitucion)="row">
                <div class="text-danger" v-b-tooltip.hover :title="row.value.error" v-if="row.value.error!==''">{{ row.value.data }}</div>
                <div class="text-primary" v-else>{{ row.value.data }}</div>
              </template>
              <template #cell(persona_ocupacion)="row">
                <div class="text-danger" v-b-tooltip.hover :title="row.value.error" v-if="row.value.error!==''">{{ row.value.data }}</div>
                <div class="text-primary" v-else>{{ row.value.data }}</div>
              </template>
              <template #cell(persona_correo_electronico)="row">
                <div class="text-danger" v-b-tooltip.hover :title="row.value.error" v-if="row.value.error!==''">{{ row.value.data }}</div>
                <div class="text-primary" v-else>{{ row.value.data }}</div>
              </template>
              <template #cell(persona_estado_civil)="row">
                <div class="text-danger" v-b-tooltip.hover :title="row.value.error" v-if="row.value.error!==''">{{ row.value.data }}</div>
                <div class="text-primary" v-else>{{ row.value.data }}</div>
              </template>
              <template #cell(persona_regimen_conyugal)="row">
                <div class="text-danger" v-b-tooltip.hover :title="row.value.error" v-if="row.value.error!==''">{{ row.value.data }}</div>
                <div class="text-primary" v-else>{{ row.value.data }}</div>
              </template>
              <template #cell(persona_tipo_identificacion)="row">
                <div class="text-danger" v-b-tooltip.hover :title="row.value.error" v-if="row.value.error!==''">{{ row.value.data }}</div>
                <div class="text-primary" v-else>{{ row.value.data }}</div>
              </template>
              <template #cell(persona_folio_identificacion)="row">
                <div class="text-danger" v-b-tooltip.hover :title="row.value.error" v-if="row.value.error!==''">{{ row.value.data }}</div>
                <div class="text-primary" v-else>{{ row.value.data }}</div>
              </template>
              <template #cell(persona_anio_ingreso)="row">
                <div class="text-danger" v-b-tooltip.hover :title="row.value.error" v-if="row.value.error!==''">{{ row.value.data }}</div>
                <div class="text-primary" v-else>{{ row.value.data }}</div>
              </template>
              <template #cell(domicilio_calle)="row">
                <div class="text-danger" v-b-tooltip.hover :title="row.value.error" v-if="row.value.error!==''">{{ row.value.data }}</div>
                <div class="text-primary" v-else>{{ row.value.data }}</div>
              </template>
              <template #cell(domicilio_numero_exterior)="row">
                <div class="text-danger" v-b-tooltip.hover :title="row.value.error" v-if="row.value.error!==''">{{ row.value.data }}</div>
                <div class="text-primary" v-else>{{ row.value.data }}</div>
              </template>
              <template #cell(domicilio_numero_interior)="row">
                <div class="text-danger" v-b-tooltip.hover :title="row.value.error" v-if="row.value.error!==''">{{ row.value.data }}</div>
                <div class="text-primary" v-else>{{ row.value.data }}</div>
              </template>
              <template #cell(domicilio_referencias)="row">
                <div class="text-danger" v-b-tooltip.hover :title="row.value.error" v-if="row.value.error!==''">{{ row.value.data }}</div>
                <div class="text-primary" v-else>{{ row.value.data }}</div>
              </template>
              <template #cell(domicilio_colonia)="row">
                <div class="text-danger" v-b-tooltip.hover :title="row.value.error" v-if="row.value.error!==''">{{ row.value.data }}</div>
                <div class="text-primary" v-else>{{ row.value.data }}</div>
              </template>
              <template #cell(domicilio_codigo_postal)="row">
                <div class="text-danger" v-b-tooltip.hover :title="row.value.error" v-if="row.value.error!==''">{{ row.value.data }}</div>
                <div class="text-primary" v-else>{{ row.value.data }}</div>
              </template>
              <template #cell(domicilio_localidad)="row">
                <div class="text-danger" v-b-tooltip.hover :title="row.value.error" v-if="row.value.error!==''">{{ row.value.data }}</div>
                <div class="text-primary" v-else>{{ row.value.data }}</div>
              </template>
              <template #cell(domicilio_municipio)="row">
                <div class="text-danger" v-b-tooltip.hover :title="row.value.error" v-if="row.value.error!==''">{{ row.value.data }}</div>
                <div class="text-primary" v-else>{{ row.value.data }}</div>
              </template>
              <template #cell(domicilio_estado)="row">
                <div class="text-danger" v-b-tooltip.hover :title="row.value.error" v-if="row.value.error!==''">{{ row.value.data }}</div>
                <div class="text-primary" v-else>{{ row.value.data }}</div>
              </template>
              <template #cell(domicilio_pais)="row">
                <div class="text-danger" v-b-tooltip.hover :title="row.value.error" v-if="row.value.error!==''">{{ row.value.data }}</div>
                <div class="text-primary" v-else>{{ row.value.data }}</div>
              </template>
              <template #cell(inversionista_institucion_bancaria)="row">
                <div class="text-danger" v-b-tooltip.hover :title="row.value.error" v-if="row.value.error!==''">{{ row.value.data }}</div>
                <div class="text-primary" v-else>{{ row.value.data }}</div>
              </template>
              <template #cell(inversionista_beneficiario)="row">
                <div class="text-danger" v-b-tooltip.hover :title="row.value.error" v-if="row.value.error!==''">{{ row.value.data }}</div>
                <div class="text-primary" v-else>{{ row.value.data }}</div>
              </template>
              <template #cell(inversionista_clabe)="row">
                <div class="text-danger" v-b-tooltip.hover :title="row.value.error" v-if="row.value.error!==''">{{ row.value.data }}</div>
                <div class="text-primary" v-else>{{ row.value.data }}</div>
              </template>
              <template #cell(inversionista_forma_pago)="row">
                <div class="text-danger" v-b-tooltip.hover :title="row.value.error" v-if="row.value.error!==''">{{ row.value.data }}</div>
                <div class="text-primary" v-else>{{ row.value.data }}</div>
              </template>
              <template #cell(inversionista_numero_cuenta)="row">
                <div class="text-danger" v-b-tooltip.hover :title="row.value.error" v-if="row.value.error!==''">{{ row.value.data }}</div>
                <div class="text-primary" v-else>{{ row.value.data }}</div>
              </template>
              <template #cell(inversionista_numero_tarjeta)="row">
                <div class="text-danger" v-b-tooltip.hover :title="row.value.error" v-if="row.value.error!==''">{{ row.value.data }}</div>
                <div class="text-primary" v-else>{{ row.value.data }}</div>
              </template>
              <template #cell(contacto1_nombre)="row">
                <div class="text-danger" v-b-tooltip.hover :title="row.value.error" v-if="row.value.error!==''">{{ row.value.data }}</div>
                <div class="text-primary" v-else>{{ row.value.data }}</div>
              </template>
              <template #cell(contacto1_correo)="row">
                <div class="text-danger" v-b-tooltip.hover :title="row.value.error" v-if="row.value.error!==''">{{ row.value.data }}</div>
                <div class="text-primary" v-else>{{ row.value.data }}</div>
              </template>
              <template #cell(contacto1_telefono)="row">
                <div class="text-danger" v-b-tooltip.hover :title="row.value.error" v-if="row.value.error!==''">{{ row.value.data }}</div>
                <div class="text-primary" v-else>{{ row.value.data }}</div>
              </template>
              <template #cell(contacto2_nombre)="row">
                <div class="text-danger" v-b-tooltip.hover :title="row.value.error" v-if="row.value.error!==''">{{ row.value.data }}</div>
                <div class="text-primary" v-else>{{ row.value.data }}</div>
              </template>
              <template #cell(contacto2_correo)="row">
                <div class="text-danger" v-b-tooltip.hover :title="row.value.error" v-if="row.value.error!==''">{{ row.value.data }}</div>
                <div class="text-primary" v-else>{{ row.value.data }}</div>
              </template>
              <template #cell(contacto2_telefono)="row">
                <div class="text-danger" v-b-tooltip.hover :title="row.value.error" v-if="row.value.error!==''">{{ row.value.data }}</div>
                <div class="text-primary" v-else>{{ row.value.data }}</div>
              </template>
              <template #cell(contador_rfc)="row">
                <div class="text-danger" v-b-tooltip.hover :title="row.value.error" v-if="row.value.error!==''">{{ row.value.data }}</div>
                <div class="text-primary" v-else>{{ row.value.data }}</div>
              </template>
              <template #cell(contador_razon_social)="row">
                <div class="text-danger" v-b-tooltip.hover :title="row.value.error" v-if="row.value.error!==''">{{ row.value.data }}</div>
                <div class="text-primary" v-else>{{ row.value.data }}</div>
              </template>
              <template #cell(contador_correo)="row">
                <div class="text-danger" v-b-tooltip.hover :title="row.value.error" v-if="row.value.error!==''">{{ row.value.data }}</div>
                <div class="text-primary" v-else>{{ row.value.data }}</div>
              </template>
              <template #cell(contador_telefono)="row">
                <div class="text-danger" v-b-tooltip.hover :title="row.value.error" v-if="row.value.error!==''">{{ row.value.data }}</div>
                <div class="text-primary" v-else>{{ row.value.data }}</div>
              </template>
              <template #cell(valido)="row">
                <div class="text-danger" v-b-tooltip.hover title="Este registro contiene errores" v-if="row.value==='No'">{{ row.value }}</div>
                <div class="text-primary" v-else>{{ row.value }}</div>
              </template>

              <template #cell(show_details)="row">
                <b-button
                  @click="row.toggleDetails"
                  variant="light"
                  class="btn-sm mb-2 btn-block btn-outline-black"
                >
                  Ver errores
                </b-button>
              </template>
              <template #row-details="row">
                <li v-for="error in row.item.errores" v-bind:key="error">
                  {{ error }}
                </li>
              </template>
            </b-table>
          </div>
        </b-card>
      </b-overlay>

      <template #modal-footer>
        <!-- Enviar para crear o actualizar -->
        <div class="w-100">
          <x-form-footer-buttons
            :disabled="Boolean(response.data) && response.data.some((row) => row.valido === 'No')"
            :loading="isLoading"
            :isEditing="false"
            :isCreating="true"
            :btnCreateTitle="response.data ? 'Aplicar cambios' : 'Subir formato'"
            @on-cancel="resetForm"
            @on-create="submit"
            @on-update="submit"
          ></x-form-footer-buttons>
        </div>
      </template>
    </b-modal>
  </div>
</template>

<script>

const payload = () => ({
  file: null,
  save_file: 0
})

const response = () => ({
  error: false,
  message: '',
  errors: {},
  importacion: null,
  data: null
})

const STORE_MODULE = 'inversionistaModule'

export default {
  name: 'InversionistaImport',

  data () {
    return {
      modalInversionistaImportForm: false,

      isLoading: false,

      payload: payload(),

      response: response(),

      fields: [
        { key: 'persona_rfc', label: 'RFC' },
        { key: 'persona_curp', label: 'CURP' },
        { key: 'persona_regimen_fiscal', label: 'Régimen Fiscal' },
        { key: 'persona_nombres_razon_social', label: 'Nombre/Razón Social' },
        { key: 'persona_primer_apellido', label: 'Primer Apellido' },
        { key: 'persona_segundo_apellido', label: 'Segundo Apellido' },
        { key: 'persona_fecha_nacimiento_constitucion', label: 'Fecha Nacimiento/Constitución' },
        { key: 'persona_lugar_nacimiento_constitucion', label: 'Lugar Nacimiento/Constitución' },
        { key: 'persona_ocupacion', label: 'Ocupación' },
        { key: 'persona_correo_electronico', label: 'Correo Electrónico' },
        { key: 'persona_estado_civil', label: 'Estado Civil' },
        { key: 'persona_regimen_conyugal', label: 'Régimen Conyugal' },
        { key: 'persona_tipo_identificacion', label: 'Tipo de Identificación' },
        { key: 'persona_folio_identificacion', label: 'Folio de Identificación' },
        { key: 'persona_anio_ingreso', label: 'Año Ingreso' },
        { key: 'domicilio_calle', label: 'Calle' },
        { key: 'domicilio_numero_exterior', label: 'No. Interior' },
        { key: 'domicilio_numero_interior', label: 'No. Exterior' },
        { key: 'domicilio_referencias', label: 'Referencias' },
        { key: 'domicilio_colonia', label: 'Colonia' },
        { key: 'domicilio_codigo_postal', label: 'Código Postal' },
        { key: 'domicilio_localidad', label: 'Localidad' },
        { key: 'domicilio_municipio', label: 'Municipio' },
        { key: 'domicilio_estado', label: 'Estado' },
        { key: 'domicilio_pais', label: 'País' },
        { key: 'inversionista_institucion_bancaria', label: 'Institución Bancaria' },
        { key: 'inversionista_beneficiario', label: 'Beneficiario' },
        { key: 'inversionista_clabe', label: 'CLABE' },
        { key: 'inversionista_forma_pago', label: 'Forma de Pago' },
        { key: 'inversionista_numero_cuenta', label: 'Número de Cuenta' },
        { key: 'inversionista_numero_tarjeta', label: 'Número de Tarjeta' },
        { key: 'contacto1_nombre', label: 'Nombre Contacto 1' },
        { key: 'contacto1_correo', label: 'Correo Contacto 1' },
        { key: 'contacto1_telefono', label: 'Teléfono Contacto 1' },
        { key: 'contacto2_nombre', label: 'Nombre Contacto 2' },
        { key: 'contacto2_correo', label: 'Correo Contacto 2' },
        { key: 'contacto2_telefono', label: 'Teléfono Contacto 2' },
        { key: 'contador_rfc', label: 'RFC Contador' },
        { key: 'contador_razon_social', label: 'Razón Social Contador' },
        { key: 'contador_correo', label: 'Correo Contador' },
        { key: 'contador_telefono', label: 'Teléfono Contador' },
        { key: 'valido', label: 'Válido' },
        { key: 'show_details', label: 'Errores' }
      ]
    }
  },

  computed: {
    valido () {
      return Boolean(response.data) && response.data.every((row) => row.valido)
    }
  },

  methods: {
    /**
     * Envía el formulario.
     *
     */
    async submit () {
      this.isLoading = true
      const { error, message, data } = Boolean(this.response.data) && this.response.data.every((row) => row.valido === 'Si')
        ? await this.$store.dispatch(`${STORE_MODULE}/save_import`, { data: this.response.importacion })
        : await this.$store.dispatch(`${STORE_MODULE}/parse_import`, {
          save_file: this.payload.save_file,
          file: this.payload.file
        })
      this.isLoading = false

      this.$notify({ error, message }, 'Envío de formulario')

      this.response.error = error
      this.response.message = message
      this.response.errors = error ? data.errors : {}
      this.response.importacion = error ? null : data.importacion
      this.response.data = error ? null : data.detalle
    },

    resetForm () {
      document.activeElement.blur()
      this.payload = payload()
      this.response = response()

      this.modalInversionistaImportForm = false
    }
  }
}
</script>

<style>
.required_form {
  color: red;
}
.isLoading {
  max-height: 400px !important;
  overflow: hidden;
}
</style>
