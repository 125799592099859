<template>
  <div>
    <slot name="activator" v-bind:onShowModal="onShowModal">
      <b-button @click="onShowModal" variant="success" size="sm">Crear</b-button>
    </slot>

    <b-modal
      v-model="modal"
      centered
      size="lg"
      scrollable
      no-close-on-backdrop
      no-close-on-esc
      hide-header-close
      dialog-class="x-modal-md"
    >
      <template #modal-title>
        <span :class="isEditingResource ? 'text-danger' : ''">{{ showFormTitle }}</span>
      </template>

      <x-alert-with-errors
        :error="response.error"
        :title="response.message"
        :errors="response.errors"
      />

      <!-- <b-row v-if="isEditingResource">
        <b-col class="d-flex flex-row">
          <b-badge variant="dark" class="mb-3 mr-2" v-text="getLastAccessText"></b-badge>
          <b-badge variant="success" class="mb-3 mr-2" v-text="getCreationDate"></b-badge>
          <b-badge variant="warning" class="mb-3" v-text="getModificationDate"></b-badge>
        </b-col>
      </b-row> -->

      <b-overlay :show="isLoading">
        <template #overlay>
          <div class="text-center">
            <loading message="Procesando información, por favor espere..." />
          </div>
        </template>
        <FormulateForm
            ref="inversionistaForm"
            name="inversionistaForm"
            v-model="formValues"
            :errors="response.errors"
            @submit="submit"
          >
            <div class="card">
              <div class="card-header">
                Información fiscal del inversionista
              </div>
              <div class="card-body">
                <b-row>
                  <b-col cols="12" md="6">
                    <!-- Tipo persona -->
                    <FormulateInput
                      name="tipo_persona"
                      label="Tipo de persona"
                      type="select"
                      :options="tipoPersona"
                      @input="onChangeTipoPersona"
                    />
                  </b-col>

                  <b-col cols="12" md="6">
                    <!-- RFC -->
                    <FormulateInput
                      name="rfc"
                      label="RFC"
                      type="text"
                    />
                  </b-col>
                </b-row>

                <!-- Regimen fiscal -->
                <FormulateInput
                  name="sat_regimen_fiscal_id"
                  validation="bail|required"
                  v-if="formValues.tipo_persona === 'MORAL'"
                >
                  <template #element>
                    <x-form-group title="Régimen fiscal" required>
                      <x-select
                        v-model="formValues.sat_regimen_fiscal_id"
                        dispatchPath="regimenModule/getResourceTipoMoral"
                        getterListPath="regimenModule/getResourceData"
                        :formatListWith="['id', 'descripcion']"
                        :disabled="isLoading">
                      </x-select>
                    </x-form-group>
                  </template>
                </FormulateInput>
                <FormulateInput
                  name="sat_regimen_fiscal_id"
                  v-else
                >
                  <template #element>
                    <x-form-group title="Régimen fiscal" required>
                      <x-select
                        v-model="formValues.sat_regimen_fiscal_id"
                        dispatchPath="regimenModule/getResourceTipoFisica"
                        getterListPath="regimenModule/getResourceData"
                        :formatListWith="['id', 'descripcion']"
                        :disabled="isLoading">
                      </x-select>
                    </x-form-group>
                  </template>
                </FormulateInput>

                <!-- Identificacion -->
                <FormulateInput
                  name="identificacion"
                  label="Inversionista"
                  type="text"
                  required
                  validation="bail|required"
                  validation-name="Identificación"
                />

                <b-row v-if="formValues.tipo_persona === 'FISICA'">
                  <!-- Nombres -->
                  <b-col cols="12" md="6">
                    <FormulateInput
                      name="nombres"
                      label="Nombres"
                      type="text"
                    />
                  </b-col>

                  <!-- Apellidos-->
                  <b-col cols="12" md="6">
                    <FormulateInput
                      name="apellidos"
                      label="Apellidos"
                      type="text"
                    />
                  </b-col>
                </b-row>
                <div v-else>
                  <b-row>
                    <!-- Razón social -->
                    <b-col cols="12" md="12">
                      <FormulateInput
                        name="razon_social"
                        label="Razón social"
                        required
                        type="text"
                        validation="bail|required"
                        validation-name="Razón social"
                      />
                    </b-col>
                  </b-row>
                </div>
              </div>
            </div>
            <div class="accordion mt-2" id="accordionExample">
              <div class="card">
                <div class="card-header" id="headingOne">
                  <h5 class="mb-0">
                    <button class="btn btn-link" type="button" data-toggle="collapse" data-target="#collapseOne" aria-expanded="true" aria-controls="collapseOne">
                      Datos del inversionista
                    </button>
                  </h5>
                </div>

                <div id="collapseOne" class="collapse show" aria-labelledby="headingOne" data-parent="#accordionExample">
                  <div class="card-body">
                    <!-- Formulario para inversionistas -->
                    <b-row>
                      <b-col cols="12" md="6">
                        <!-- Fecha Nacimiento/Constitución -->
                        <FormulateInput
                          name="fecha"
                          :label="formValues.tipo_persona === 'FISICA' ? 'Fecha de nacimiento (dd/mm/yyyy)' : 'Fecha de constitución (dd/mm/yyyy)'"
                          type="text"
                        />
                      </b-col>

                      <b-col cols="12" md="6">
                        <!-- Lugar Nacimiento/Constitución -->
                        <FormulateInput
                          name="lugar"
                          :label="formValues.tipo_persona === 'FISICA' ? 'Lugar de nacimiento' : 'Lugar de constitución'"
                          type="text"
                        />
                      </b-col>
                    </b-row>

                    <b-row v-if="formValues.tipo_persona === 'FISICA'">
                      <b-col cols="12" md="12">
                        <!-- Ocupación -->
                        <FormulateInput
                          name="ocupacion"
                          label="Ocupación"
                          type="text"
                          placeholder="Lic., Ing., C.P."
                        />
                      </b-col>
                    </b-row>

                    <!-- Email -->
                    <FormulateInput
                      name="email"
                      label="Correo electrónico"
                      required
                      type="email"
                      validation="bail|required|email"
                      validation-name="Correo electrónico"
                      error-behavior="live"
                    >
                      <template #element>
                        <b-input-group>
                          <input
                            v-model="formValues.email"
                            type="email"
                            placeholder="usuario@dominio.com"
                            class="form-control"
                          />
                          <template #append v-if="isEditingResource">
                            <b-button
                              @click="sendResetPasswordEmail(formValues.email)"
                              size="sm"
                              :variant="recoverPassword.variant"
                              v-b-tooltip.hover
                              :disabled="recoverPassword.sending"
                              :title="
                                recoverPassword.sending ? 'Enviando correo...' : recoverPassword.message
                              "
                            >
                              <b-icon-envelope></b-icon-envelope>
                            </b-button>
                          </template>
                        </b-input-group>
                      </template>
                    </FormulateInput>

                    <!-- Contraseña -->
                    <FormulateInput
                      v-if="!isEditingResource"
                      name="password"
                      label="Contraseña"
                      required
                      autocomplete="on"
                      error-behavior="live"
                      validation="bail|required|min:10,length|max:32,length|noRepeatingCharacters|notConsecutiveNumbers|atLeastOneMayus|atLeastOneSymbol|atLeastOneNumber"
                      :validation-rules="$getPasswordValidationRules"
                      :validation-messages="$getPasswordValidationMessages"
                    >
                      <template #element>
                        <div class="input-group">
                          <div class="input-group-prepend">
                            <b-button @click="getGeneratedPassword" variant="primary">Generar</b-button>
                          </div>
                          <input
                            :value="formValues.password"
                            :type="showingPassword.value ? 'text' : 'password'"
                            readonly
                            class="form-control"
                          />
                          <div class="input-group-append">
                            <b-button
                              @click="toggleShowPassword"
                              variant="danger"
                              size="sm"
                              v-b-tooltip.hover
                              :title="showingPassword.text"
                            >
                              <b-icon-eye v-if="showingPassword.value"></b-icon-eye>
                              <b-icon-eye-slash v-else></b-icon-eye-slash>
                            </b-button>
                          </div>
                        </div>
                      </template>
                    </FormulateInput>

                    <b-row v-if="formValues.tipo_persona === 'FISICA'">
                      <b-col cols="12" md="6">
                        <!-- Estado Civil -->
                        <FormulateInput
                          name="estado_civil"
                          label="Estado Civil"
                          type="text"
                        />
                      </b-col>
                      <b-col cols="12" md="6">
                        <!-- Régimen Conyugal -->
                        <FormulateInput
                          name="regimen_conyugal"
                          label="Régimen Conyugal"
                          type="select"
                          :options="regimenConyugalOptions"
                        />
                      </b-col>
                    </b-row>

                    <b-row v-if="formValues.tipo_persona === 'FISICA'">
                      <b-col cols="12" md="6">
                        <!-- Tipo Identificación -->
                        <FormulateInput
                          name="tipo_identificacion"
                          label="Tipo de Identificación"
                          type="select"
                          :options="tipoIdentificacionOptions"
                        />
                      </b-col>
                      <b-col cols="12" md="6">
                        <!-- Folio Identificación -->
                        <FormulateInput
                          name="folio_identificacion"
                          label="Folio de Identificación"
                          type="text"
                        />
                      </b-col>
                    </b-row>
                    <b-row>
                      <b-col cols="12" md="6">
                        <!-- Anio ingreso -->
                        <FormulateInput
                          name="anio_ingreso"
                          label="Año de ingreso"
                          type="number"
                        />
                      </b-col>
                      <b-col cols="12" md="6">
                        <!-- Notas -->
                        <FormulateInput
                          name="notas"
                          label="Notas"
                          type="text"
                        />
                      </b-col>
                    </b-row>
                  </div>
                </div>
              </div>
              <div class="card">
                <div class="card-header" id="headingTwo">
                  <h5 class="mb-0">
                    <button class="btn btn-link collapsed" type="button" data-toggle="collapse" data-target="#collapseTwo" aria-expanded="false" aria-controls="collapseTwo">
                      Domicilio del inversionista
                    </button>
                  </h5>
                </div>
                <div id="collapseTwo" class="collapse" aria-labelledby="headingTwo" data-parent="#accordionExample">
                  <div class="card-body">
                    <b-row>
                      <b-col cols="12" md="4">
                        <!-- Calle -->
                        <FormulateInput
                          name="calle"
                          label="Calle"
                          type="text"
                        />
                      </b-col>

                      <b-col cols="12" md="4">
                        <!-- Número -->
                        <FormulateInput
                          name="no_exterior"
                          label="No. Exterior"
                          type="text"
                        />
                      </b-col>
                      <b-col cols="12" md="4">
                        <!-- Número interior-->
                        <FormulateInput
                          name="no_interior"
                          label="No. Interior"
                          type="text"
                        />
                      </b-col>
                    </b-row>
                    <!-- Referencias -->
                    <FormulateInput
                      name="referencia"
                      label="Referencia"
                      type="text"
                    />
                    <b-row>
                      <b-col cols="12" md="4">
                        <!-- Colonia -->
                        <FormulateInput
                          name="colonia"
                          label="Colonia"
                          type="text"
                        />
                      </b-col>

                      <b-col cols="12" md="4">
                        <!-- Código postal -->
                        <FormulateInput
                          name="codigo_postal"
                          label="Código postal"
                          type="text"
                        />
                      </b-col>
                      <b-col cols="12" md="4">
                        <!-- Localidad -->
                        <FormulateInput
                          name="localidad"
                          label="Localidad"
                          type="text"
                        />
                      </b-col>
                    </b-row>
                    <b-row>
                      <b-col cols="12" md="4">
                        <!-- Colonia -->
                        <FormulateInput
                          name="municipio"
                          label="Municipio"
                          type="text"
                        />
                      </b-col>

                      <b-col cols="12" md="4">
                        <!-- Código postal -->
                        <FormulateInput
                          name="estado"
                          label="Estado"
                          type="text"
                        />
                      </b-col>
                      <b-col cols="12" md="4">
                        <!-- Localidad -->
                        <FormulateInput
                          name="pais"
                          label="País"
                          type="text"
                        />
                      </b-col>
                    </b-row>
                  </div>
                </div>
              </div>
              <div class="card">
                <div class="card-header" id="headingPago">
                  <h5 class="mb-0">
                    <button class="btn btn-link collapsed" type="button" data-toggle="collapse" data-target="#collapsePago" aria-expanded="false" aria-controls="collapsePago">
                      Medio de pago del inversionista
                    </button>
                  </h5>
                </div>
                <div id="collapsePago" class="collapse" aria-labelledby="headingPago" data-parent="#accordionExample">
                  <div class="card-body">
                    <b-row>
                      <!-- Institucion bancaria -->
                      <b-col cols="12" md="6">
                        <FormulateInput
                          name="institucion_bancaria_id"
                        >
                          <template #element>
                            <x-form-group title="Institución bancaria">
                              <x-select
                                v-model="formValues.institucion_bancaria_id"
                                dispatchPath="institucionBancariaModule/getResource"
                                getterListPath="institucionBancariaModule/getData"
                                :formatListWith="['id', 'nombre']"
                                :disabled="isLoading">
                              </x-select>
                            </x-form-group>
                          </template>
                        </FormulateInput>
                      </b-col>
                      <!-- Beneficiario -->
                      <b-col cols="12" md="6">
                        <!-- Beneficiario -->
                        <FormulateInput
                          name="beneficiario"
                          label="Beneficiario"
                          type="text"
                        />
                      </b-col>
                    </b-row>
                    <b-row>
                      <b-col cols="12" md="6">
                        <!-- CLABE -->
                        <FormulateInput
                          name="clabe"
                          label="CLABE"
                          type="text"
                        />
                      </b-col>
                      <b-col cols="12" md="6">
                        <!-- Forma de pago -->
                        <FormulateInput
                          name="forma_pago"
                          label="Forma de pago"
                          type="select"
                          :options="formaPagoOptions"
                          validations="required"
                          :validation-messages="{
                            required: 'Se requiere seleccionar la forma de pago.'
                          }"
                        />
                      </b-col>
                    </b-row>
                    <b-row>
                      <b-col cols="12" md="6">
                        <!-- Número de cuenta -->
                        <FormulateInput
                          name="cuenta"
                          label="Número de cuenta"
                          type="text"
                        />
                      </b-col>

                      <b-col cols="12" md="6">
                        <!-- Número de tarjeta -->
                        <FormulateInput
                          name="num_tarjeta"
                          label="Número de tarjeta"
                          type="text"
                        />
                      </b-col>
                    </b-row>
                  </div>
                </div>
              </div>
              <div class="card">
                <div class="card-header" id="headingThree">
                  <h5 class="mb-0">
                    <button class="btn btn-link collapsed" type="button" data-toggle="collapse" data-target="#collapseThree" aria-expanded="false" aria-controls="collapseThree">
                      Medios de contacto del inversionista
                    </button>
                  </h5>
                </div>
                <div id="collapseThree" class="collapse" aria-labelledby="headingThree" data-parent="#accordionExample">
                  <div class="card-body">
                    <div class="mb-3">
                      <strong>Contacto 1</strong>
                    </div>
                    <b-row>
                      <b-col cols="12" md="6">
                        <FormulateInput
                          name="contacto1"
                          label="Nombre completo"
                          type="text"
                        />
                      </b-col>

                      <b-col cols="12" md="6">
                        <FormulateInput
                          name="correo1"
                          label="Correo electrónico principal"
                          type="text"
                        />
                      </b-col>
                    </b-row>
                    <b-row>
                      <b-col cols="12" md="6">
                        <FormulateInput
                          name="correo1a"
                          label="Correo electrónico secundario"
                          type="text"
                        />
                      </b-col>

                      <b-col cols="12" md="6">
                        <FormulateInput
                          name="telefono1"
                          label="Teléfono"
                          type="text"
                        />
                      </b-col>
                    </b-row>

                    <div class="mb-3">
                      <strong>Contacto 2</strong>
                    </div>
                    <b-row>
                      <b-col cols="12" md="12">
                        <FormulateInput
                          name="contacto2"
                          label="Nombre completo"
                          type="text"
                        />
                      </b-col>
                    </b-row>
                    <b-row>
                      <b-col cols="12" md="6">
                        <FormulateInput
                          name="correo2"
                          label="Correo electrónico"
                          type="text"
                        />
                      </b-col>

                      <b-col cols="12" md="6">
                        <FormulateInput
                          name="telefono2"
                          label="Teléfono"
                          type="text"
                        />
                      </b-col>
                    </b-row>
                  </div>
                </div>
              </div>
              <div class="card">
                <div class="card-header" id="headingFour">
                  <h5 class="mb-0">
                    <button class="btn btn-link collapsed" type="button" data-toggle="collapse" data-target="#collapseFour" aria-expanded="false" aria-controls="collapseThree">
                      Datos del contador
                    </button>
                  </h5>
                </div>
                <div id="collapseFour" class="collapse" aria-labelledby="headingFour" data-parent="#accordionExample">
                  <div class="card-body">
                    <!-- Formulario para contador -->
                    <b-row>
                      <!-- Nombre -->
                      <b-col cols="12" md="6">
                        <FormulateInput
                          name="razon_social_contador"
                          label="Razón social"
                          type="text"
                        />
                      </b-col>
                      <b-col cols="12" md="6">
                        <!-- RFC -->
                        <FormulateInput
                          name="rfc_contador"
                          label="RFC"
                          type="text"
                        />
                      </b-col>
                    </b-row>

                    <b-row>
                      <b-col cols="12" md="6">
                        <!-- Email -->
                        <FormulateInput
                          name="email_contador"
                          label="Correo electrónico"
                          type="email"
                        >
                          <template #element>
                            <b-input-group>
                              <input
                                v-model="formValues.email_contador"
                                type="email"
                                placeholder="usuario@dominio.com"
                                class="form-control"
                              />
                            </b-input-group>
                          </template>
                        </FormulateInput>
                      </b-col>

                      <b-col cols="12" md="6">
                        <!-- Teléfonos -->
                        <FormulateInput
                          name="telefonos_contador"
                          label="Teléfonos"
                          type="text"
                        >
                          <template #element>
                            <b-form-tags
                              class="form-control"
                              input-id="tags-basic-contador"
                              separator=" ,;"
                              remove-on-delete
                              placeholder="Agregue nuevos números telefónicos separando con espacios, comas o punto y coma"
                              add-button-text="Agregar"
                              v-model="formValues.telefonos_contador"
                            >
                            </b-form-tags>
                          </template>
                        </FormulateInput>
                      </b-col>
                    </b-row>
                  </div>
                </div>
              </div>
            </div>
          </FormulateForm>
      </b-overlay>

      <template #modal-footer>
        <!-- Enviar para crear o actualizar -->
        <div class="w-100">
          <x-form-footer-buttons
            :disabled="$refs.inversionistaForm ? $refs.inversionistaForm.hasErrors : false"
            :loading="isLoading"
            :isEditing="isEditingResource"
            :isCreating="!isEditingResource"
            @on-cancel="resetForm"
            @on-create="submit"
            @on-update="submit"
          ></x-form-footer-buttons>
        </div>
      </template>
    </b-modal>
  </div>
</template>

<script>
import { mapState, mapGetters } from 'vuex'
import moment from 'moment'

const formValues = () => ({
  email: '',
  rfc: '',
  sat_regimen_fiscal_id: '',
  nombres: '',
  apellidos: '',
  fecha: '',
  lugar: '',
  razon_social: '',
  tipo_persona: '',
  anio_ingreso: '',
  notas: '',
  identificacion: '',
  ocupacion: '',
  estado_civil: '',
  regimen_conyugal: '',
  tipo_identificacion: '',
  folio_identificacion: '',
  calle: '',
  no_exterior: '',
  no_interior: '',
  referencia: '',
  colonia: '',
  codigo_postal: '',
  localidad: '',
  municipio: '',
  estado: '',
  pais: '',
  contacto1: '',
  correo1: '',
  correo1a: '',
  telefono1: '',
  contacto2: '',
  correo2: '',
  telefono2: '',
  razon_social_contador: '',
  rfc_contador: '',
  email_contador: '',
  telefonos_contador: [],
  institucion_bancaria_id: '',
  clabe: '',
  beneficiario: '',
  cuenta: '',
  num_tarjeta: '',
  forma_pago: ''
})

const showingPassword = () => ({
  value: false,
  text: 'Mostrar contraseña',
  type: 'password'
})

const recoverPassword = () => ({
  sending: false,
  message: 'Enviar email para recuperación de contraseña',
  variant: 'warning'
})

const response = () => ({
  error: false,
  message: '',
  errors: {}
})

const API_MODULE = 'inversionistaModule'

export default {
  name: 'InversionistaForm',

  props: {
    value: {
      type: Boolean,
      required: true
    }
  },

  async created () {
    //
    // Obtiene todos los roles del sistema
    await this.$store.dispatch('roleModule/getSystemRoles')
  },

  data () {
    return {
      modal: this.value,

      isLoading: false,

      formValues: formValues(),

      currentRoles: [],

      regimenDispatchPath: 'regimenModule/getResourceTipoFisica',

      tipoPersona: [
        { value: 'FISICA', label: 'Física' },
        { value: 'MORAL', label: 'Moral' }
      ],

      formaPagoOptions: [
        { value: 'TRANSFERENCIA', label: 'Transferencia' },
        { value: 'EFECTIVO', label: 'Efectivo' }
      ],

      regimenConyugalOptions: [
        'Separación de Bienes', 'Sociedad Conyugal', 'Régimen Mixto'
      ],

      tipoIdentificacionOptions: [
        'INE', 'Pasaporte', 'Cédula Profesional', 'CSF'
      ],

      showingPassword: showingPassword(),

      recoverPassword: recoverPassword(),

      response: response()
    }
  },

  computed: {
    //
    // User module
    ...mapState(API_MODULE, ['editingResource']),
    ...mapGetters(API_MODULE, ['isEditingResource']),

    showFormTitle () {
      if (this.isEditingResource) {
        if (this.editingResource.persona_fisica) {
          return this.isEditingResource
            ? `Editando inversionista: '${this.editingResource.persona_fisica.nombre_completo}'`
            : 'Crear nuevo inversionista'
        } else {
          return this.isEditingResource
            ? `Editando inversionista: '${this.editingResource.persona_moral.razon_social}'`
            : 'Crear nuevo inversionista'
        }
      } else {
        return 'Crear nuevo inversionista'
      }
    },

    getLastAccessText () {
      if (!this.isEditingResource) return ''

      return this.editingResource.ultimo_acceso === 'Sin registro de ingreso'
        ? this.editingResource.ultimo_acceso
        : `Último acceso: ${this.$formatDate(this.editingResource.ultimo_acceso)}`
    },

    getCreationDate () {
      if (!this.isEditingResource) return ''

      return `Creación: ${this.$customFormatDate(this.editingResource.fecha_creacion, 'DD/MMMM/YYYY')}`
    },

    getModificationDate () {
      if (!this.isEditingResource) {
        return ''
      }

      return `Modificación: ${this.$customFormatDate(
        this.editingResource.ultima_modificacion,
        'DD/MMMM/YYYY'
      )}`
    }
  },

  watch: {
    isEditingResource: {
      handler (value) {
        this.recoverPassword = recoverPassword()

        if (value) {
          const { id, email, rfc, regimen_fiscal, persona_fisica, persona_moral, tipo_persona, anio_ingreso, notas, beneficiario, institucion_bancaria_id, clabe, cuenta, num_tarjeta, forma_pago, identificacion, domicilio, contacto1, correo1, correo1a, telefono1, contacto2, correo2, telefono2, contador } = this.editingResource
          this.formValues.id = id
          this.formValues.email = email
          this.formValues.rfc = rfc
          this.formValues.sat_regimen_fiscal_id = regimen_fiscal.id
          this.formValues.nombres = persona_fisica ? persona_fisica.nombres : null
          this.formValues.apellidos = persona_fisica ? persona_fisica.apellidos : null
          this.formValues.ocupacion = persona_fisica ? persona_fisica.ocupacion : null
          this.formValues.estado_civil = persona_fisica ? persona_fisica.estado_civil : null
          this.formValues.regimen_conyugal = persona_fisica ? persona_fisica.regimen_conyugal : null
          this.formValues.tipo_identificacion = persona_fisica ? persona_fisica.tipo_identificacion : null
          this.formValues.folio_identificacion = persona_fisica ? persona_fisica.folio_identificacion : null
          this.formValues.razon_social = persona_moral ? persona_moral.razon_social : null
          this.formValues.fecha = persona_fisica ? persona_fisica.fecha_nacimiento : (persona_moral ? persona_moral.fecha_constitucion : null)
          this.formValues.lugar = persona_fisica ? persona_fisica.lugar_nacimiento : (persona_moral ? persona_moral.lugar_constitucion : null)
          this.formValues.tipo_persona = tipo_persona
          this.formValues.anio_ingreso = anio_ingreso
          this.formValues.notas = notas
          this.formValues.beneficiario = beneficiario
          this.formValues.institucion_bancaria_id = institucion_bancaria_id
          this.formValues.clabe = clabe
          this.formValues.beneficiario = beneficiario
          this.formValues.cuenta = cuenta
          this.formValues.num_tarjeta = num_tarjeta
          this.formValues.forma_pago = forma_pago
          this.formValues.identificacion = identificacion
          this.formValues.calle = domicilio.calle
          this.formValues.no_exterior = domicilio.no_exterior
          this.formValues.no_interior = domicilio.no_interior
          this.formValues.referencia = domicilio.referencia
          this.formValues.colonia = domicilio.colonia
          this.formValues.codigo_postal = domicilio.codigo_postal
          this.formValues.localidad = domicilio.localidad
          this.formValues.municipio = domicilio.municipio
          this.formValues.estado = domicilio.estado
          this.formValues.pais = domicilio.pais
          this.formValues.contacto1 = contacto1
          this.formValues.correo1 = correo1
          this.formValues.correo1a = correo1a
          this.formValues.telefono1 = telefono1
          this.formValues.contacto2 = contacto2
          this.formValues.correo2 = correo2
          this.formValues.telefono2 = telefono2
          this.formValues.razon_social_contador = contador ? contador.razon_social : null
          this.formValues.rfc_contador = contador ? contador.rfc : null
          this.formValues.email_contador = contador ? contador.email : null
          this.formValues.telefonos_contador = contador ? contador.telefonos : null

          return
        }

        this.resetForm()
      }
    },

    value: {
      immediate: true,
      handler (value) {
        this.modal = value
        this.getGeneratedPassword()
      }
    },

    modal: {
      immediate: true,
      handler (value) {
        this.$emit('input', value)
      }
    }
  },

  methods: {
    /**
     * Envía el formulario.
     *
     * Envía el formulario para su creación o su actualización.
     * Dependiendo si la variable "isEditingResource" es verdadera actualizará
     * si no es así entonces mandará crear.
     */
    async submit () {
      this.response = response()
      this.prepareInput()

      this.isLoading = true
      const { error, message, data } = this.isEditingResource
        ? await this.updateInversionista(this.formValues)
        : await this.createInversionista(this.formValues)
      this.isLoading = false

      this.$notify({ error, message }, 'Envío de formulario')

      this.response.error = error
      this.response.message = message
      this.response.errors = error ? data.errors : {}

      if (!error) this.resetForm()
    },

    async createInversionista (form) {
      return await this.$store.dispatch(`${API_MODULE}/createResource`, form)
    },

    async updateInversionista (form) {
      return await this.$store.dispatch(`${API_MODULE}/updateResource`, {
        id: form.id,
        payload: form
      })
    },

    /**
     * Prepara los datos antes de enviarlos al backend.
     *
     * Prepara los datos necesarios como la fecha o los roles y permisos que
     * se deben procesar en el backend.
     */
    prepareInput () {
    },

    getGeneratedPassword () {
      const password = this.$onGeneratePassword()
      this.formValues.password = password
      this.$forceUpdate()
    },

    toggleShowPassword () {
      this.showingPassword.value = !this.showingPassword.value
      this.showingPassword.text = this.showingPassword.value
        ? 'Ocultar contraseña'
        : 'Mostrar contraseña'
      this.showingPassword.type = this.showingPassword.value ? 'text' : 'password'
    },

    resetForm () {
      document.activeElement.blur()
      this.$store.commit(`${API_MODULE}/setResourceToEdit`, null)
      this.$formulate.reset('inversionistaForm')
      this.formValues = formValues()
      this.response = response()
      this.showingPassword = showingPassword()
      this.recoverPassword = recoverPassword()

      this.modal = false
    },

    async sendResetPasswordEmail (email) {
      this.recoverPassword.sending = true

      const getval = await this.$store.dispatch('authModule/recoverPassword', email)

      this.$notify(getval, 'Envio de email')

      this.recoverPassword.sending = false
      this.recoverPassword.message = getval.error
        ? 'Reintentar enviar correo de recuperación de contraseña'
        : getval.message
      this.recoverPassword.variant = getval.error ? 'danger' : 'success'
    },

    onInputFechaIngreso (value) {
      this.formValues.fecha_ingreso = moment(value).format('YYYY-MM-DD')
    },

    onShowModal () {
      this.modal = true
      this.$emit('input', this.modal)
    },

    async onChangeTipoPersona (value) {
      const regimenDispatchPath = `regimenModule/${value === 'FISICA' ? 'getResourceTipoFisica' : 'getResourceTipoMoral'}`
      await this.$store.dispatch(regimenDispatchPath)
      this.regimenDispatchPath = regimenDispatchPath
    }
  }
}
</script>

<style>
.required_form {
  color: red;
}
.isLoading {
  max-height: 400px !important;
  overflow: hidden;
}
</style>
