<template>
  <base-view title="Inversionistas" icon="users">
    <div>
      <check-authorization :requiresAuthorizations="['indice inversionistas']">
        <x-alert-with-errors
          :error="Object.keys(inversionistasConEmailsInvalidos).length > 0"
          class="mb-2"
          title="Contraseñas no válidas"
          message="Corrige los correos electrónicos de los siguientes inversionistas:"
          :errors="inversionistasConEmailsInvalidos"
        />
        <div class="card card-header-actions mb-4">
          <b-card-header>
            Inversionistas

            <div class="w-120 ml-auto d-flex justify-content-end align-items-center">
              <check-authorization
                :requiresAuthorizations="['importar inversionistas']"
                :overrideIf="$store.getters['inversionistaModule/isEditingResource']"
              >
                <inversionista-import
                  :value="showImportForm || isEditingResource"
                  @input="showImportForm = $event"
                />
              </check-authorization>
              <!-- Form -->
              <check-authorization
                :requiresAuthorizations="['crear inversionistas']"
                :overrideIf="$store.getters['inversionistaModule/isEditingResource']"
              >
                <inversionista-form
                  :value="showForm || isEditingResource"
                  @input="showForm = $event"
                />
              </check-authorization>
              <!-- Filter -->
              <b-form-select v-model="empresa_id" :options="options" size="sm" class="w-100 mx-2" style="max-width: 400px"
                             :disabled="loading_options"
              ></b-form-select>
              <!-- Search -->
              <inversionista-search
                placeholder="Buscar por identificación, correo o rfc"
                :disabled="!$store.getters['inversionistaModule/getAvailableList']"
                @filter="(filter) => this.filter = filter"></inversionista-search>
              <!-- Options -->
              <div class="dropdown no-caret">
                <button
                  class="btn btn-transparent-dark btn-icon dropdown-toggle"
                  type="button"
                  data-toggle="dropdown"
                >
                  <i data-feather="more-vertical"></i>
                </button>
                <div class="dropdown-menu dropdown-menu-right animated--fade-in-up">
                  <a class="dropdown-item my-cursor" @click="getResource(null)">
                    Recargar
                  </a>
                </div>
              </div>
            </div>
          </b-card-header>

          <b-card-body class="p-2">
            <inversionista-list @on-got-item="showForm = true" :search="empresa_id" :filter="filter"></inversionista-list>
          </b-card-body>
        </div>
      </check-authorization>
    </div>
  </base-view>
</template>

<script>
import { mapGetters, mapActions } from 'vuex'
import InversionistaImport from '../../components/Operaciones/Inversionistas/InversionistaImport.vue'
import InversionistaForm from '@/components/Operaciones/Inversionistas/InversionistaForm'
import InversionistaList from '@/components/Operaciones/Inversionistas/InversionistaList'
import InversionistaSearch from '@/components/Operaciones/Inversionistas/InversionistaSearch'

export default {
  name: 'InversionistasMainView',

  components: {
    InversionistaForm,
    InversionistaList,
    InversionistaSearch,
    InversionistaImport
  },

  mounted () {
    this.init()
  },

  data () {
    return {
      hideButton: false,
      showForm: false,
      showImportForm: false,
      filter: null,
      inversionistasConEmailsInvalidos: {
        type: Object
      },
      empresa_id: null,
      options: [],
      loading_options: true
    }
  },

  computed: mapGetters('inversionistaModule', ['isEditingResource']),

  methods: {
    ...mapActions('inversionistaModule', ['getResource']),

    async init () {
      this.inversionistasConEmailsInvalidos = {}
      this.loading_options = true
      this.load_options()
      this.loading_options = false
    },

    async load_options () {
      // Load options empresa
      const { data } = await this.$store.dispatch('empresaModule/getSelectTree')
      const options = [{
        value: null,
        text: '-- Seleccione una empresa o desarrollo -- '
      }]
      for (var i = 0; i < data.length; i++) {
        options.push({
          value: {
            empresa_id: data[i].id,
            condominio_id: null,
            subcondominio_id: null
          },
          text: `Empresa: ${data[i].razon_social}`
        })
        for (var j = 0; j < data[i].condominios.length; j++) {
          options.push({
            value: {
              empresa_id: data[i].id,
              condominio_id: data[i].condominios[j].id,
              subcondominio_id: null
            },
            text: `-- Desarrollo: ${data[i].condominios[j].nombre}`
          })
          for (var k = 0; k < data[i].condominios[j].subcondominios.length; k++) {
            options.push({
              value: {
                empresa_id: data[i].id,
                condominio_id: data[i].condominios[j].id,
                subcondominio_id: data[i].condominios[j].subcondominios[k].id
              },
              text: `---- Etapa: ${data[i].condominios[j].subcondominios[k].nombre}`
            })
          }
        }
      }
      this.options = options
    },

    async sendContrasenasInversionistas () {
      console.log('enviar contraseñas')
      const inversionistas = this.$store.getters['inversionistaModule/getResource'].data
      let i = 0

      this.inversionistasConEmailsInvalidos = {}

      // save emails no validos in array emailsNoValidos
      inversionistas.forEach(inversionista => {
        if (inversionista.identificacion !== 'INMOBILIARIA UNIVERSITY CITY SA DE CV') {
          if (!this.$isEmailValid(inversionista.email)) {
            this.inversionistasConEmailsInvalidos[i] = [`${inversionista.identificacion} - Correo: ${inversionista.email ? inversionista.email : 'No asignado'}`]
            i++
          }
        }
      })
      console.log(this.inversionistasConEmailsInvalidos)
      console.log(Object.keys(this.inversionistasConEmailsInvalidos).length)

      if (Object.keys(this.inversionistasConEmailsInvalidos).length === 0) {
        const { error, message } = await this.$store.dispatch('inversionistaModule/contrasenasInversionistas')

        if (error) this.$notify({ error, message }, 'Contraseñas de inversionistas')

        this.$notify({ message }, 'Contraseñas de inversionistas')
      }
    }
  }
}
</script>
